import React, { useEffect } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { useFlexMedImages } from "./hooks/useFlexMedImages"
import Container from "../Layout/Container"
import Hero from "../Layout/Hero"
import { animate } from "services/animations"

const FlexMedHowItWorks = () => {
  useEffect(() => {
    animate({
      y: 50,
      type: "from",
      isScrollTrigger: true,
      target: ".fade-from-upper-howitworks",
      animateOn: ".animate-trigger",
    })
  }, [])
  const data = useFlexMedImages()
  return (
    <Hero size="small">
      <Container isCentered className="mt-1 mb-1">
        <h3 className="has-text-centered has-text-primary">
          How the new Medical Allowance Program works
        </h3>
        <p className="has-text-centered">
          The Medical Allowance Program makes getting healthy convenient and
          flexible
        </p>
      </Container>
      <section className="animate-trigger"></section>

      <Container isCentered>
        <Container desktop={11} fullhd={11}>
          <div className="columns has-text-centered">
            <div className="column is-4 fade-from-upper-howitworks">
              <Img fixed={data.flexmedHowToClick.childImageSharp.fixed} />
              <h4 className="mt-0 mb-1">Upload</h4>
              <ol className="has-text-left">
                <li className="pb-1">
                  Click <strong>Upload Receipts</strong> at{" "}
                  <Link
                    to="/"
                    className="has-text-weight-bold has-text-primary"
                  >
                    maersk.medgrocer.com
                  </Link>
                </li>
                <li className="pb-1">Upload receipts and required documents</li>
                <li className="pb-1">Input details</li>
              </ol>
              {/* <section className="animate-trigger"></section> */}
            </div>

            <div className="column is-1 mt-2 px-1 is-hidden-mobile fade-from-upper-howitworks">
              <Img fluid={data.processTriangleGrey.childImageSharp.fluid} />
              {/* <section className="animate-trigger"></section> */}
            </div>

            <div className="column is-4 fade-from-upper-howitworks">
              <Img fixed={data.flexmedHowToSubmit.childImageSharp.fixed} />
              <h4 className="mt-0 mb-1">Submit</h4>
              <ol className="has-text-left">
                <li className="pb-1">
                  Submit required documents at the 7th floor payroll area
                </li>
                <li className="pb-1">Sign the receipt log</li>
              </ol>
              {/* <section className="animate-trigger"></section> */}
            </div>

            <div className="column is-1 mt-2 px-1 is-hidden-mobile fade-from-upper-howitworks">
              <Img fluid={data.processTriangleGrey.childImageSharp.fluid} />
              {/* <section className="animate-trigger"></section> */}
            </div>

            <div className="column is-4 fade-from-upper-howitworks">
              <Img fixed={data.flexmedHowToConfirm.childImageSharp.fixed} />
              <h4 className="mt-0 mb-1">Receive</h4>
              <ol className="has-text-left">
                <li className="pb-1">
                  Receive email acknowledging online submission
                </li>
                <li className="pb-1">
                  Receive email with approved reimbursement amount
                </li>
                <li className="pb-1">
                  Receive reimbursement in the next payroll period
                </li>
              </ol>
              {/* <section className="animate-trigger"></section> */}
            </div>
          </div>
        </Container>
      </Container>
    </Hero>
  )
}

export default FlexMedHowItWorks
