import React, { useEffect } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { useFlexMedImages } from "./hooks/useFlexMedImages"
import Container from "../Layout/Container"
import Hero from "../Layout/Hero"
import { animate } from "services/animations"

const FlexMedCovered = () => {
  useEffect(() => {
    animate({
      x: -100,
      type: "from",
      isScrollTrigger: true,
      target: ".fade-from-left-covered",
      animateOn: ".animate-trigger-covered",
    })
  }, [])
  const data = useFlexMedImages()
  return (
    <Hero>
      <Hero isCentered color="light" size="small">
        <p>Want to maximize your Medical Allowance?</p>
        <h3 className="m-1">Check before you request</h3>
      </Hero>

      <Container isCentered>
        <h3>What's Covered</h3>
        <section className="animate-trigger-covered"></section>
        <div className="columns">
          <div className="column">
            <div className="columns is-vcentered mt-1 fade-from-left-covered">
              <div className="column has-text-centered mr-1">
                <Img
                  fixed={data.flexmedCoverageMedicine.childImageSharp.fixed}
                />
                <h4 className="mt-0">Medical</h4>
              </div>

              <div className="column content">
                <ul>
                  <li>Prescription drugs and vaccines</li>
                  <li>Over-the-counter drugs</li>
                  <li>Laboratory tests and check-ups</li>
                </ul>
              </div>
            </div>

            <hr className="fade-from-left-covered" />

            <div className="columns is-vcentered mt-1 fade-from-left-covered">
              <div className="column has-text-centered mr-1">
                <Img
                  fixed={data.flexmedCoverageOptical.childImageSharp.fixed}
                />
                <h4 className="mt-0">Optical</h4>
              </div>

              <div className="column content">
                <ul>
                  <li>Eyeglasses and contact lenses</li>
                  <li>Saline solutions</li>
                  <li>Optical check-ups</li>
                </ul>
              </div>
            </div>

            <hr className="fade-from-left-covered" />

            <div className="columns is-vcentered fade-from-left-covered">
              <div className="column has-text-centered mr-1">
                <Img fixed={data.flexmedCoverageDental.childImageSharp.fixed} />
                <h4 className="mt-0">Dental</h4>
              </div>

              <div className="column content">
                <ul>
                  <li>Braces and fillings</li>
                  <li>Cleaning and sealants</li>
                  <li>Dental check-ups</li>
                </ul>
              </div>
            </div>

            <hr className="fade-from-left-covered" />

            <div className="columns is-vcentered fade-from-left-covered">
              <div className="column has-text-centered mr-1">
                <Img
                  fixed={data.flexmedCoverageWellness.childImageSharp.fixed}
                />
                <h4 className="mt-0">Wellness</h4>
              </div>

              <div className="column content">
                <ul>
                  <li>Gym, yoga, boxing, etc.</li>
                  <li>
                    <span className="has-text-grey-light">
                      Other wellness memberships
                    </span>
                  </li>
                  <li>
                    <del className="has-text-grey-light">
                      Medical and gym equipment
                    </del>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Hero>
  )
}

export default FlexMedCovered
