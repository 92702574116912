import React, { useEffect } from "react"
import Img from "gatsby-image"
import { useFlexMedImages } from "./hooks/useFlexMedImages"
import Container from "../Layout/Container"
import Hero from "../Layout/Hero"
import { animate } from "services/animations"

const FlexMedRequired = () => {
  useEffect(() => {
    animate({
      x: -100,
      type: "from",
      isScrollTrigger: true,
      target: ".fade-from-left-required",
      animateOn: ".animate-trigger-required",
    })
  }, [])
  const data = useFlexMedImages()
  return (
    <Hero>
      <Container isCentered>
        <h3>What's Required</h3>
        <section className="animate-trigger-required"></section>
        <div className="columns">
          <div className="column">
            <div className="columns is-vcentered mt-1 fade-from-left-required">
              <div className="column has-text-centered mr-1">
                <Img
                  fixed={data.flexmedRequiredReceipt.childImageSharp.fixed}
                />
                <h4 className="mt-0">Receipt</h4>
              </div>

              <div className="column content">
                <ul>
                  <li>Official receipts or sales invoices with TIN</li>
                  <li>Named to employee or immediate family</li>
                  <li>Original, clear, and untampered</li>
                </ul>
              </div>
            </div>

            <hr className="fade-from-left-required" />

            <div className="columns is-vcentered mt-1 fade-from-left-required">
              <div className="column has-text-centered mr-1">
                <Img fixed={data.flexmedRequiredRx.childImageSharp.fixed} />
                <h4 className="mt-0"> Prescription for:</h4>
              </div>

              <div className="column content">
                <ul>
                  <li>Prescription drugs and vaccines</li>
                  <li>Eyewear (e.g. reading glasses)</li>
                  <li>Laboratory tests</li>
                  <li>Antipsychotic/ antidepressant drugs</li>
                </ul>
              </div>
            </div>

            <hr className="fade-from-left-required" />

            <div className="columns is-vcentered mt-1 fade-from-left-required">
              <div className="column has-text-centered mr-1">
                <Img
                  fixed={data.flexmedRequiredDocuments.childImageSharp.fixed}
                />
                <h4 className="mt-0">Domestic/common law spouse</h4>
              </div>

              <div className="column content">
                <ul>
                  <li>NSO birth certificate</li>
                  <li>NSO marriage contract (if applicable)</li>
                  <li>
                    Barangay certification of co-habitation for more than 12
                    months
                  </li>
                  <li>NSO Certification of No Marriage (CENOMAR)</li>
                </ul>
              </div>
            </div>

            <hr className="fade-from-left-required" />

            <div className="columns is-vcentered mt-1 fade-from-left-required">
              <div className="column has-text-centered mr-1">
                <Img fixed={data.flexmedRequiredOnline.childImageSharp.fixed} />
                <h4 className="mt-0">Online purchase</h4>
              </div>

              <div className="column content">
                <ul>
                  <li>Transaction form</li>
                  <li>Deposit slip or credit card billing statement</li>
                </ul>
              </div>
            </div>

            <hr className="fade-from-left-required" />
          </div>
        </div>
      </Container>
    </Hero>
  )
}

export default FlexMedRequired
