import React from "react"
import moment from "moment"
import { GATSBY_WEBSITE_SHUTDOWN_DATE } from "gatsby-env-variables"
import SEO from "../components/Layout/SEO"
import Layout from "../components/Layout/Layout"
import HomeBanner from "../components/FlexMed/FlexMedBanner"
import HomeCovered from "../components/FlexMed/FlexMedCovered"
import HomeRequired from "../components/FlexMed/FlexMedRequired"
import HomeHowItWorks from "../components/FlexMed/FlexMedHowItWorks"

export default () => {
  if (moment(GATSBY_WEBSITE_SHUTDOWN_DATE).isSameOrBefore(moment(), "day"))
    return <HomeBanner />

  return (
    <Layout>
      <SEO title="Home" />
      <HomeBanner />
      <HomeHowItWorks />
      <HomeCovered />
      <HomeRequired />
    </Layout>
  )
}
